.text-dark {
    color: theme-color("dark");
}
.lightGreenBackground {
  background-color: #ADF6A7;
}
.lightRedBackground {
  background-color: #FF7F7F;
}

.page_wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    /* background-color: #F0F7FD; */
}
.page_container {
    background-color: #F4F7FF;
    box-shadow: 0px 0px 5px #ddd;
}
.cover_image {
    min-height: 180px;
    background-position: center;
    border-radius: 6px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}


.front_page_box {
    padding: 0.2rem 1.2rem !important;

}

.front_page_box:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Card look */
  transition: 0.3s;
}

.programs_list {
    margin-left: -1.2rem;
}

.front_page_box_parent{
    margin-top: 10px;
    margin-bottom: 50px;
}

.frontpage_program_title {
    margin-top: 10px;
}

.channel_name {
    margin-bottom: -10px;
}

.count_box {
    margin-top: -1rem;
}

.tags_base span{
    background-color: $gray-300;
    padding: 0.4rem 0.8rem;
    color: $black;
    margin-right: 0.3rem;
    border-radius: 8px;
}

.video_count span{
    background-color: $gray-300;
    padding: 0.4rem 0.8rem;
    color: $black;
    font-size: 0.8rem;
    margin-right: 0.3rem;
    margin-left: -0.5rem;
    border-radius: 8px;
}

.description_text {
    margin-top: 1rem;
}

.programs_list {
    margin-bottom: 2rem;
}

.program_page_videolist  {
    margin-bottom: 1rem;
}

/* TABLES */

.table .thead-dark th {
    color: #fff;
    background-color: theme-color("primary");
}
table {
    border:none;
    border-collapse: collapse;
}

table.vertical_borders td {
    border-left: 1px solid $gray-200;
    border-right: 1px solid $gray-200;
}

table td:first-child {
    border-left: none;
}

table td:last-child {
    border-right: none;
}

.table_auto .subtitle {
  background-color: $table-auto-subtitle-color !important;
  color: #000;
  font-weight: bold;
}
.table_auto .subtitle td {
  border-color: $table-auto-subtitle-color;
  border-width: 1px;
  border-style: solid;
}

/* TABS */

.nav-tabs {
    border-bottom: 0px solid #dee2e6;
}
.nav-tabs > li {
    background-color: #fefefe;
    border-radius: 4px 4px 0 0;
    // box-shadow: 0px 0px 5px #ddd;
    font-weight: 900;
    padding-left: 32px;
    padding-right: 32px;
}

/* BUTTONS */

.btn-bold {
    font-weight: 900;
}

/* INPUTS */

.form-control {
    font-size: 0.96rem;
    padding: 0.425rem 0.45rem;
}
.inputFileHidden {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    width: 30;
}

/* HELPERS */
.cursor_pointer {
    cursor: pointer;
}
.w-32 {
    width: 32px;
}
.h-32 {
    height: 32px;
}
.border-left-3 {
    border-left-width:3px !important;
}
.s-5 {
  font-size: 200%;
}

/* PAD */
.pad-warning {
    background-color: theme-color("warning");
}
.pad-danger {
    background-color: theme-color("danger");
}
.pad-success {
    background-color: theme-color("success");
}


/* TEXT */
h2{
    font-size: 2.3rem;
    font-weight: 600;
    color: $black;
}
.program_page_videolist h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: $black;
}

.frontpage_program_title a{
    font-size: 1.4rem;
    font-weight: 400;
    color: $black;
}

.channel_program_list p {
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
    color: $black;
}


.h6-base p{
    font-size: 0.9rem;
    font-weight: 300;
    color: $gray-800;
}

.frontpage_program_name p{
    font-size: 0.9rem;
    font-weight: 300;
    color: $gray-800;
}

.frontpage_program_name, .channel_user_name{
    margin-bottom: 5px;
    margin-top: -5px;
}



.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}

.card-columns {
    @include media-breakpoint-only(lg) {
      column-count: 2;
    }
    @include media-breakpoint-only(xl) {
      column-count: 3;
    }
}

.static_with_border {
    pointer-events: none;
    box-shadow: 0 1px #44444440;
    border: 1px solid #E0E0E09A;
}

.static_without_border {
    pointer-events: none;
    box-shadow: none;
}

.header_btn_without_border {
    box-shadow: none;
}

.active_color {
    color: $brand;
}


.card_position__price {
    transform: translate(-115px, 155px);
    position: absolute;
}

.card_position__expertise {
    transform: translate(190px, 165px);
    position: absolute;
}

.card_freetime_area {
    transform: translate(15px, -15px);
    align-items: flex-end;
    // position: absolute;
}

.card_img {
    object-fit: cover;
}

.profile_position__price {
    transform: translate(-115px, 130px);
    position: absolute;
}

.profile_calender_selection_area {
    background: #F7FAFC;
    border: 1px solid #E0E0E09A;
    border-radius: 0.5em;
}

.calender_freeTab {
    padding: 0;
    transform: translate(2px, -8px);
    position: absolute;
}

.calender_reservedTab {
    padding: 0;
    transform: translate(2px, -8px);
    position: absolute;
}

.dropdown {
    padding: 5px;
    border-radius: 5%;
}

.dropdown__nav {
    padding: 5px;
    border-radius: 5%;
    border: 1px solid #DEE2E6;
}

.datepicker {
    border: 1px solid #d3d3d3;
    border-radius: 0.3em;
    padding: 5px;
}

.txt_handler {
    display: block;
}

.breadcrumb {
    // padding: 12px;
    border: 1px solid #E0E0E0;
    // opacity: 0.33;
}

.saoy_min_height {
    min-height: 800px;
}


//SKELETON LOADER
// %loading-skeleton {
//     color: transparent;
//     appearance: none;
//     -webkit-appearance: none;
//     background-color: #eee;
//     border-color: #eee;

//     &::placeholder {
//         color: transparent;
//     }
// }
// @keyframes loading-skeleton {
// from {
//     opacity: .4;
// }
// to {
//     opacity: 1;
// }
// }
// .loading-skeleton {
//     pointer-events: none;
//     animation: loading-skeleton 1s infinite alternate;

//     img {
//         filter: grayscale(100) contrast(0%) brightness(1.8);
//     }
// h1, h2, h3, h4, h5, h6,
// p, li,
// .btn,
// label,
// .form-control {
//     @extend %loading-skeleton;
// }
// }

.skeleton {
    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
    border-radius: 0.125rem;
}

.skeleton-text {
    width: 100%;
    height: 12px;
    margin-bottom: 0.25rem;
    border-radius: 0.125rem;
}

.skeleton-text:last-child {
    margin-bottom: 0;
    width: 80%;
}

.skeleton-button {
    width: 80px;
    height: 19.3px !important;
    position: relative;
}
.skeleton-button:not(:last-child) {
    margin-right: 0.5rem;
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 70%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.custom-checkbox {
    appearance: none; 
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
  
.custom-checkbox:checked {
background-color: #007bff;
border-color: #007bff;
}
  
.custom-checkbox:checked::after {
content: '✔';
color: white;
font-size: 14px;
}

.custom-checkbox:hover {
border-color: #999;
}

@keyframes wave {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    50% {
        transform: translateY(-10px);
        opacity: 0.5;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.loading-text {
    display: inline-block;
    font-size: 1.2rem; /* Tekstin koko */
    animation: wave 1s infinite ease-in-out; /* Kesto, toistot ja animaatiokäyrä */
    color: $brand; /* Tekstin väri */
  }



/* RESPONSIVE */

@media screen and (min-width: 693px) {
    
  }

@media screen and (max-width: 692px) {
   
  }