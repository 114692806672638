/* *{
  margin: 0;
  padding: 0;
} */

.page_imgUploader{
  height: 400px;
  background-color: #F4F7FF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_imgUploader {
  width: 310px;
  height: 427px;
  padding: 15px;
  margin-top: 90px;
  border: 10px;
  border-radius: 25px;
  background-color: #FFFFFF;
  box-shadow: 15px 10px 25px 0px  #1f8ae1;
}

.heading_imgUploader {
  text-align: center;
  margin-top: 1rem;
  /* font-weight: bold; */
}

.img-holder_imgUploader {
  margin-top: 1rem;
  width: 200px;
  height: 200px;
  margin: auto;
  background: linear-gradient(270deg, #1f8ae1, #1F63E1);
  overflow: hidden;
  border-radius: 50%;
}

.img_imgUploader {
  padding: 6px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

#input[type="file"]{
  display: none;
}

.label_imgUploader {
  width: 100%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.image-upload_imgUploader {
  width: 200px;
  height: 50px;
  background-color: #1F63E1;
  color: white;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  border: none;
  letter-spacing: 1px;
  font-size: 18px;
  padding-top: 10px;
}

.image-save_imgUploader {
  width: 200px;
  height: 50px;
  background-color: #1F63E1;
  color: white;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  border: none;
  letter-spacing: 1px;
  font-size: 18px;
}
