.footer_container_full
{
  background-color: #EFF0F5;
  padding-top: 48px;
  padding-bottom: 48px;
}
.footer_container {
  background-color: #EFF0F5;
}
.footer_logo {
  max-width: 300px;
}
.footer_link {
  font-size: 17.6px;
  font-weight: 600;
  /* color: rgba(0,0,0,0.79); */
}