.table .thead-dark th a.table_header_link {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.limit_column_size {
    word-wrap: break-word;
    max-width: 150px !important;
    text-overflow: ellipsis;
    overflow: hidden;
}